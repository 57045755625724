// import Swiper from 'swiper';
// import Swiper from "swiper/bundle";

export default function () {

  const swiper = new Swiper('#index-swiper', {
    // Optional parameters
    slidesPerView: "auto",
    spaceBetween: 80,
    allowTouchMove: false,
    speed: 8000,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    loop: true,
    centeredSlides: true,
  
    breakpoints: {
      767: {
        // autoHeight: false,
        // slidesPerView: 2,
        // slidesPerGroup: 2,
        // spaceBetween: 50,
      },
    },
  });

}
export default function () {
    //Get the button:
  let mybutton = document.getElementById("scrollBtn");

  // When the user scrolls down 20px from the top of the document, show the button

  function getScrollBottom() {
    var body = window.document.body;
    var html = window.document.documentElement;
    var scrollTop = body.scrollTop || html.scrollTop;
    return html.scrollHeight - html.clientHeight - scrollTop;
  }

  function scrollFunction() {
    let scrollBottom = getScrollBottom();

    if ( (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) && scrollBottom > 200) {
      mybutton.style.display = "flex";
    } else {
      mybutton.style.display = "none";
    }
    
  }

  window.onscroll = function() {scrollFunction()};

  mybutton.addEventListener('click', ()=>{
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  })
}